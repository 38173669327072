import { useEffect, useState } from "react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { MdNotes } from "react-icons/md";
import Button from "../../../../common/button";
import NavWrapper from "./Header.style";
import MobileMenu from "../mobileMenu/MobileMenu";
const Header = () => {
  const [isMobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };
  useEffect(() => {
    const header = document.getElementById("navbar");
    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });

    return () => {
      window.removeEventListener("sticky", handleScroll);
    };
  }, []);
  return (
    <NavWrapper className="bithu_header" id="navbar">
      <div className="container">
        {/* Main Menu Start */}
        <div className="bithu_menu_sect">
          <div className="bithu_menu_left_sect">
            <div className="logo">
              <a href="/">WENLABZ</a>
            </div>
          </div>
          <div className="bithu_menu_right_sect bithu_v1_menu_right_sect">
            <div className="bithu_menu_list">
              <ul>
                <li className="submenu">
                  <a href="#">WENLABZ Terminal+</a>
                  <div className="sub_menu_sect">
                    <ul className="sub_menu_list">
                      <li>
                        <a href="/terminal" target="_blank">
                          Report #1
                        </a>
                      </li>
                      <li>
                        <a href="/terminal2" target="_blank">
                          Report #2
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li>
                  <a href="/form" target="_blank">
                    Application Form
                  </a>
                </li>
              </ul>
            </div>
            <div className="bithu_menu_btns">
              <div className="menu_btn" onClick={() => handleMobileMenu()}>
                <MdNotes />
              </div>
              <a href="https://discord.gg/JRHZqPuyEV">
                <Button sm variant="outline" className="join_btn">
                  <FaDiscord /> Discord
                </Button>
              </a>
              <a href="https://twitter.com/wen_labz" target="_blank">
                <Button sm variant="outline" className="join_btn">
                  <FaTwitter /> Twitter
                </Button>
              </a>
            </div>
          </div>
        </div>
        {/* <!-- Main Menu END --> */}
        {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
      </div>
    </NavWrapper>
  );
};

export default Header;
