import Header from "../components/section/header/v1/Header";
import Layout from "../common/layout";
import Banner from "../components/section/banner/v1";
import Banner2 from "../components/section/banner2/v1";
import React, { useState, useEffect } from "react";
const HomeV1 = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 500);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <div>
      {isDesktop ? (
        <Layout>
          <Header />
          <Banner />
        </Layout>
      ) : (
        <Layout>
          <Header />
          <Banner2 />
        </Layout>
      )}
    </div>
  );
};

export default HomeV1;
